import { useKeycloak } from '@react-keycloak/web';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '../../state/hooks';
import { companySelector, getCompany } from '../../state/slices';
import { useSelector } from 'react-redux';
import { Button, Card, CardContent, CardHeader, Grid, TextField } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const SelectWallet: FC = () => {
    const dispatch = useAppDispatch();
    const { keycloak, initialized } = useKeycloak();
    const [searchParams] = useSearchParams();
    const [walletUrl, setWalletUrl] = useState<string>('');
    const configurationId = searchParams.get('id');
    let navigate = useNavigate();
    const company = useSelector(companySelector);

    console.log('QQQ keycloak.token: ', keycloak.token);
    useEffect(() => {
            dispatch(getCompany({jwtToken: keycloak.token!}));
    }, [keycloak.token]);

    useEffect(() => {
        if (company.singleItem?.companyWalletUrl !== undefined) {
            selectWallet(company.singleItem?.companyWalletUrl);
        }
        setWalletUrl((company.singleItem?.companyWalletUrl === undefined) ? '' : company.singleItem?.companyWalletUrl);
    }, [company.singleItem?.companyWalletUrl]);

    function selectWallet(walletlUrl : string) {
        navigate('/issue?id=' + configurationId + '&callback=' + walletlUrl);
    }

    return (
        <div>
            <Card variant="outlined">
                <CardHeader title='Select your organizational wallet'/>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2} sx={{alignItems: 'center'}}>
                                <Grid item xs={2}>
                                    Other Web Wallet
                                </Grid>
                                <Grid item xs={7}>
                                    <TextField  variant='outlined' placeholder="Specify the Wallet URL" fullWidth margin="dense" value={walletUrl} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setWalletUrl(event.target.value);
                                    }}></TextField>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant='outlined' onClick={() => selectWallet(walletUrl)}>Select</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
};
