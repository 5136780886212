import { Avatar, Box, IconButton, Link, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useKeycloak } from '@react-keycloak/web';
import { Logo } from '../atoms';
import { Edit, Logout } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface Props {
}


export const Header: React.FC<Props> = (props) => {
    let {keycloak} = useKeycloak();
    let navigate = useNavigate();

    const [userName, setUserName] = useState<string>(' ');

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    function logoff() {
        keycloak.logout();
    }

    useEffect(() => {
        keycloak.loadUserInfo().then((userInfo: any) => {
            if (userInfo.name === undefined) {
                setUserName(userInfo.preferred_username);
            } else {
                setUserName(userInfo.name);
            }
        });
    }, [keycloak]);


    function editProfile() {
        navigate('/company/edit');
    }

    return (

    <Box sx={{boxShadow: '0 2px 4px 0 rgba(0,0,0,.1)'}}>
            <Box sx={{background: 'linear-gradient(90deg, #ff9300, #aa418c)', height: '8px'}}/>
            <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'flex-end', background: '#efefef', padding: '.5rem 0', paddingLeft: '80px', paddingRight: '80px'}}>
                <Link sx={{display: 'flex', alignItems: 'center'}} underline="none">
                    <Typography sx={{paddingLeft: '8px'}}>{userName}</Typography>
                    <React.Fragment>
                        <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ml: 2}}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <Avatar sx={{width: 32, height: 32}}>{userName?.substring(0, 1)?.toUpperCase()}</Avatar>
                            </IconButton>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            transformOrigin={{horizontal: 'right', vertical: 'top'}}
                            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                        >
                            <MenuItem onClick={logoff} >
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                Uitloggen
                            </MenuItem>
                            <MenuItem onClick={editProfile} >
                                <ListItemIcon>
                                    <Edit fontSize="small" />
                                </ListItemIcon>
                                Profiel bewerken
                            </MenuItem>
                        </Menu>
                    </React.Fragment>

                </Link>
            </Box>
            <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'flex-start', background: '#ffffff', padding: '.5rem 0', paddingLeft: '80px', paddingRight: '80px', height: '66px'}}>
                <Logo/>
            </Box>
        </Box>
    );
};

const TopRow = styled.div`
    background: linear-gradient(90deg, #ff9300, #aa418c);
    height: 8px;
`;
const NavRow = styled.nav`
    display: flex;
    align-items: center;
    background: #efefef;
    justify-content: flex-end;
    padding-right: 40px;
`;
