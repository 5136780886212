import { useKeycloak } from '@react-keycloak/web';
import { FC, useEffect } from 'react';
import { ShowCompany } from './ShowCompany';
import { MainLayout } from '../templates';

export const LoginCheck: FC = () => {

    const { keycloak, initialized } = useKeycloak();

    useEffect(() => {
        if (initialized) {
            if (!keycloak.authenticated) {
                keycloak.login();
            }
        }
    }, [initialized]);

    return (<MainLayout />);
};
