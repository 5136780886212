import { createSlice } from '@reduxjs/toolkit';
import { defaultGenericState, GenericState } from '../model';
import { addGenericStateListBuilders, addGenericStateSingleBuilders } from '../slice';
import { getCredential, issueCredential, verifyCredential } from './CredentialApi';


export interface Credential {
    oidcUrl: string;
    sessionId: string;
}


export interface CredentialState extends GenericState<Credential> {
}

export const credentialSlice = createSlice({
    name: 'credential',
    initialState: defaultGenericState,
    reducers: {},
    extraReducers: builder => {
        addGenericStateSingleBuilders(builder, issueCredential);
        addGenericStateSingleBuilders(builder, verifyCredential);
        addGenericStateListBuilders(builder, getCredential);
    },
});
