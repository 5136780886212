import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';

import { bearerAuth } from '../auth';
import axios from 'axios';


export const getCompany = createAsyncThunk(
        'company/getCompany', ({jwtToken}: {jwtToken: string | undefined}, thunkAPI) => {
            thunkAPI.dispatch(setLoading(true));
            const config = {
                headers: {'Authorization': bearerAuth(jwtToken)}
            };
            return axios.get('/manage/company', config)
                .then(response => {
                    return response.data
                })
                .finally(() => {
                    thunkAPI.dispatch(setLoading(false));
                });
        },
);

export const saveCompany = createAsyncThunk(
        'company/saveCompany', ({jwtToken, companyWalletUrl}: {jwtToken: string | undefined, companyWalletUrl: string | undefined}, thunkAPI) => {
            thunkAPI.dispatch(setLoading(true));
            const config = {
                headers: {'Authorization': bearerAuth(jwtToken)}
            };
            const body = {
                companyWalletUrl: (companyWalletUrl === undefined) ? '' : companyWalletUrl
            };
            return axios.post('/manage/company', body, config)
                .then(response => {
                    return response.data
                })
                .finally(() => {
                    thunkAPI.dispatch(setLoading(false));
                });
        },
);
