import React from 'react';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Provider } from 'react-redux';
import { store } from './state/store';
import { AuthenticationProvider } from './components/molecules/AuthenticationProvider';
import AuthenticationStateHandler from './components/organisms/AuthenticationStateHandler';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { EditCompany, IssueVc, MainLayout } from './components';
import { LoginCheck } from './components/pages/LoginCheck';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { ShowCompany } from './components/pages/ShowCompany';
import { configureAxiosDefaults } from './AxiosConfig';
import { SelectWallet } from './components/pages/SelectWallet';
import { ScanQRCode } from './components/pages/ScanQRCode';
import { LoginWithQRCode } from './components/pages/LoginWithQRCode';


function App() {

    const theme = createTheme({
        palette: {
            primary: {
                light: '#757ce8',
                main: '#aa418c',
                dark: '#002884',
                contrastText: '#fff',
            },
            secondary: {
                light: '#ff7961',
                main: '#f44336',
                dark: '#ba000d',
                contrastText: '#000',
            },
        },
    });

    configureAxiosDefaults(store);
    return (
        <Provider store={store}>
            <CssBaseline />
            <ThemeProvider theme={theme}>
            <BrowserRouter>
                <AuthenticationProvider>
                    <AuthenticationStateHandler>
                        <AuthenticationStateHandler.Loading>
                            <div>Loading</div>
                        </AuthenticationStateHandler.Loading>
                        <AuthenticationStateHandler.AuthenticationExpired>
                            <div>Expired token</div>
                        </AuthenticationStateHandler.AuthenticationExpired>
                        <AuthenticationStateHandler.UnAuthenticated>
                            <Routes>
                                <Route path="/" element={<LoginCheck/>}>
                                    <Route path="/" element={<ShowCompany/>}/>
                                    <Route path="/issue/wallet" element={<SelectWallet/>}/>
                                    <Route path="/issue/personal" element={<ScanQRCode/>}/>
                                    <Route path="/issue" element={<IssueVc/>}/>
                                    <Route path="/company/edit" element={<EditCompany/>}/>
                                </Route>
                                <Route path="/oidc" element={<MainLayout/>}>
                                    <Route path="/oidc/login" element={<LoginWithQRCode/>}/>
                                </Route>
                            </Routes>
                        </AuthenticationStateHandler.UnAuthenticated>
                        <AuthenticationStateHandler.Authenticated>
                            <Routes>
                                <Route path="/" element={<MainLayout/>}>
                                    <Route path="/" element={<ShowCompany/>}/>
                                    <Route path="/issue/wallet" element={<SelectWallet/>}/>
                                    <Route path="/issue/personal" element={<ScanQRCode/>}/>
                                    <Route path="/issue" element={<IssueVc/>}/>
                                    <Route path="/company/edit" element={<EditCompany/>}/>
                                </Route>
                            </Routes>
                        </AuthenticationStateHandler.Authenticated>
                    </AuthenticationStateHandler>
                </AuthenticationProvider>
            </BrowserRouter>
            </ThemeProvider>
        </Provider>

    );
}

export default App;
