import { useKeycloak } from '@react-keycloak/web';
import React, { FC, useEffect } from 'react';
import { useAppDispatch } from '../../state/hooks';
import { companySelector, getCompany } from '../../state/slices';
import { useSelector } from 'react-redux';
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Modal } from '@mui/material';
import { formatDateForDisplay } from '../../state/DateUtils';
import { useNavigate } from 'react-router-dom';

export const ShowCompany: FC = () => {
    const dispatch = useAppDispatch();
    const { keycloak, initialized } = useKeycloak();
    const company = useSelector(companySelector);
    let navigate = useNavigate();
    console.log('QQQ keycloak.token: ', keycloak.token);
    useEffect(() => {
            dispatch(getCompany({jwtToken: keycloak.token!}));
    }, [keycloak.token]);


    function issueKvkCredential() {
        navigate('/issue/wallet?id=KVKRegistration_jwt_vc_json');
    }

    function issueRsinCredential() {
        navigate('/issue/wallet?id=RSIN_jwt_vc_json');
    }

    function issueLpidCredential() {
        navigate('/issue/wallet?id=LPID_jwt_vc_json');
    }

    function issueUboCredential() {
        navigate('/issue/wallet?id=UBO_jwt_vc_json');
    }

    function issueBevoegdheidCredential() {
        navigate('/issue/personal?id=BevoegdheidUittreksel_jwt_vc_json');
    }

    return (
        <div>
            <Grid container spacing={2} flexDirection={'row'}>
                <Grid item xs={6}>
            <Card variant="outlined" >
                <CardHeader title='Company'/>
                <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        Naam:
                    </Grid>
                    <Grid item xs={8}>
                        {company.singleItem?.naam}
                    </Grid>
                    <Grid item xs={4}>
                        Rechtsvorm:
                    </Grid>
                    <Grid item xs={8}>
                        {company.singleItem?.rechtsvorm}
                    </Grid>
                    <Grid item xs={4}>
                        KvK nummer:
                    </Grid>
                    <Grid item xs={8}>
                        {company.singleItem?.kvkNummer}

                    </Grid>
                    <Grid item xs={4}>
                        Rsin:
                    </Grid>
                    <Grid item xs={8}>
                        {company.singleItem?.rsin}
                    </Grid>
                    <Grid item xs={4}>
                        Startdatum:
                    </Grid>
                    <Grid item xs={8}>
                        {formatDateForDisplay(company.singleItem?.startdatum)}
                    </Grid>
                    <Grid item xs={4}>
                        Einddatum:
                    </Grid>
                    <Grid item xs={8}>
                        {formatDateForDisplay(company.singleItem?.einddatum)}
                    </Grid>
                </Grid>
                </CardContent>
                <CardActions>
                    <Button variant='outlined' onClick={issueLpidCredential}>LPID credential</Button>
                    <Button variant='outlined' onClick={issueKvkCredential}>KVK registratie credential</Button>
                    <Button variant='outlined' onClick={issueRsinCredential}>RSIN credential</Button>
                    <Button variant='outlined' onClick={issueUboCredential}>UBO credential</Button>
                </CardActions>
            </Card>
                </Grid>
                {company.singleItem?.authorizedRepresentatives && company.singleItem?.authorizedRepresentatives?.length > 0 ?
                    <Grid item xs={6}>
            <Card variant="outlined" >
                <CardHeader title='Bevoegdheid'/>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            Naam:
                        </Grid>
                        <Grid item xs={8}>
                            {company.singleItem?.authorizedRepresentatives![0].name}
                        </Grid>
                        <Grid item xs={4}>
                            Functie:
                        </Grid>
                        <Grid item xs={8}>
                            {company.singleItem?.authorizedRepresentatives![0].functionName}
                        </Grid>
                        <Grid item xs={4}>
                            Soort bevoegdheid:
                        </Grid>
                        <Grid item xs={8}>
                            {company.singleItem?.authorizedRepresentatives![0].authorizationType}
                        </Grid>
                        <Grid item xs={12}>&nbsp;</Grid>
                        <Grid item xs={12}>&nbsp;</Grid>
                        <Grid item xs={12}>&nbsp;</Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Button variant='outlined' onClick={issueBevoegdheidCredential}>Bevoegdheid credential</Button>
                </CardActions>
            </Card>
        </Grid> : <></> }
                    </Grid>
        </div>
    );
};
