import { Outlet } from 'react-router-dom';

import React, { useEffect } from 'react';
import { Header } from '../organisms/Header';
import styled from 'styled-components';
import { useKeycloak } from '@react-keycloak/web';

export const MainLayout = () => {
  return (
      <Root>
          <Header/>
          <BodyContainer>
            <Outlet/>
          </BodyContainer>
      </Root>
  );
};


const Root = styled.div`
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1440px;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    width: 100%;
`;
const BodyContainer = styled.div`
    padding: 80px;
`;
