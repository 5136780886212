import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { companySlice, CompanyState, globalSlice, GlobalState } from './slices';
import { credentialSlice, CredentialState } from './slices/credential';


export interface ApplicationState {
    globalState: GlobalState;
    companyState: CompanyState;
    credentialState: CredentialState;
}

const rootReducer = combineReducers<ApplicationState>({
    globalState: globalSlice.reducer,
    companyState: companySlice.reducer,
    credentialState: credentialSlice.reducer

});
export const store = configureStore({
    reducer: rootReducer
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
