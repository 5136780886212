import { createSlice } from '@reduxjs/toolkit';
import { defaultGenericState, GenericState } from '../model';
import { addGenericStateSingleBuilders } from '../slice';
import { getCompany, saveCompany } from './CompanyApi';


export interface Company {
    kvkNummer: string;
    naam: string;
    rsin: string;
    rechtsvorm: string;
    startdatum: Date;
    einddatum: Date;
    companyWalletUrl: string;
    authorizedRepresentatives: AuthorizedRespresentative[];
}

export interface AuthorizedRespresentative {
    id: number;
    name: string;
    functionName: string;
    authorizationType: string;
}

export interface CompanyState extends GenericState<Company> {
}

export const companySlice = createSlice({
    name: 'company',
    initialState: defaultGenericState,
    reducers: {},
    extraReducers: builder => {
        addGenericStateSingleBuilders(builder, getCompany);
        addGenericStateSingleBuilders(builder, saveCompany);
    },
});
